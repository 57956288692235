import React from "react";
import Header from "./Header";
import useGetData from "./hooks/useGetData";
import * as constants from "./constants";
import "./App.scss";

function App() {
  const [{ data: user, error: userError, isLoading: userLoading }] = useGetData(
    constants.getUserUrl,
    {},
    true
  );

  return (
    <>
      <Header user={user} userError={userError} userLoading={userLoading} />
      {/* {ReactDOM.createPortal(
        <Footer phoneUrl={getPhoneUrlFromNav(nav)} />,
        document.getElementById("cc-aoo-theme-footer")
      )} */}
    </>
  );
}

export default App;
