import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import axios from "axios";
import useIsMountedRef from "./useIsMountedRef";

// This hook takes a url and optional initial data to be returned
// before the get call returns or when a null url is passed in.
// Optional third parameter is whether to add a cache buster to the get call.

// We use a reducer to ensure referential equality of whatever is returned
// when state does not change.
// Return values:
// [{data, error, isLoading}, doGetCallback]
// The doGetCallback is ensured to remain stable so it can be used as dependency
// in useEffect and similar

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT":
      return { ...state, error: null, isLoading: true };
    case "SUCCESS":
      return {
        ...state,
        data: action.data,
        error: null,
        isLoading: false,
      };
    case "FAILURE":
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      throw new Error(
        "useGetData reducer called without matching action.type ... Received action.type:",
        action.type
      );
  }
};

const useGetData = (
  url,
  initialData = null,
  needsCacheBuster = false
  // skipError = false
) => {
  const [currentUrl, setCurrentUrl] = useState(url);
  // We use a ref to prevent an infinite loop when initial data arrays and objects
  // are passed in. Objects and arrays defined in react functional components are
  // referentially different on every render, even when the data remains exactly the same.
  // This causes an infinite loop when they are passed into a useEffect hook that
  // updates state (as we do here). Stabilizing them in a ref prevents this issue.
  const initialDataRef = useRef(initialData);

  const [state, dispatch] = useReducer(reducer, {
    data: initialDataRef.current,
    error: null,
    isLoading: false,
  });

  const isMountedRef = useIsMountedRef();

  function doGet(url) {
    console.log("doing get, url:", url);
    setCurrentUrl(url);
  }

  const doGetCallback = useCallback(doGet);

  useEffect(() => {
    const addCacheBuster = (url) => {
      if (process.env.NODE_ENV === "development") {
        return url;
      }
      const busterPrefix = url.indexOf("?") === -1 ? "?" : "&";
      return url + busterPrefix + "cachebuster=" + Date.now();
    };

    const fetchData = async () => {
      if (!isMountedRef.current) {
        return;
      }
      dispatch({ type: "INIT" });

      let _currentUrl = currentUrl;
      if (needsCacheBuster) {
        _currentUrl = addCacheBuster(currentUrl);
      }

      try {
        const res = await axios.get(_currentUrl);

        if (isMountedRef.current) {
          // console.log("res", res);

          // Below can be used to test error conditions
          // if (skipError) {
          //   dispatch({ type: "SUCCESS", data: res.data });
          // } else {
          //   dispatch({
          //     type: "FAILURE",
          //     error: { message: "Something went wrong!" },
          //   });
          // }

          dispatch({ type: "SUCCESS", data: res.data });
        }
      } catch (error) {
        if (isMountedRef.current) {
          console.log("error", error);
          console.log("error.response", error.response);
          dispatch({ type: "FAILURE", error: error });
        }
      }
    };

    if (currentUrl) {
      fetchData();
    } else {
      // reset data to initial data if no url is given
      dispatch({ type: "SUCCESS", data: initialDataRef.current });
    }
  }, [currentUrl, initialDataRef, isMountedRef, needsCacheBuster]);

  return [state, doGetCallback];
};

export default useGetData;
