import React from "react";
import ReactDOM from "react-dom";
import "./SAP-icons.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("cc-aoo-theme-header"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (process.env.NODE_ENV === "development") {
  const isPlone = process.env.REACT_APP_IS_PLONE
    ? process.env.REACT_APP_IS_PLONE.toLowerCase() === "true"
    : false;

  if (isPlone) {
    console.log(
      "WEBPACK DEV SERVER INFO: Shell environment variable 'IS_PLONE' is set to 'true'. \nUsing plone dev webpack config.  To change this set 'IS_PLONE' to 'false'.\n"
    );
  } else {
    console.log(
      "WEBPACK DEV SERVER INFO: Using local dev webpack config, NOT configured for plone development.  \nTo change this set the shell environement variable 'IS_PLONE' to 'true'.\n"
    );
  }
}
