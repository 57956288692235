import React from "react";
import logo from "./images/Company_Logo.png";
import useBreakpoint from "./hooks/useBreakpoint";
import classNames from "classnames";

function Header({ user, userError, userLoading }) {
  const shortenMsg = useBreakpoint("(max-width: 820px)");
  // In case we ever want to match the user selected style from Mohawk's SAP site
  // "cozy" class can be toggled to expand header a bit
  const headerClasses = classNames("cc-aoo-header", { cozy: false });

  return (
    <div className={headerClasses}>
      {/* <a href="/">
        <img
          src="https://hollygordonrealty-theme.s3.amazonaws.com/holly.svg"
          alt="Holly Gordon Realty"
        />
      </a> */}
      <img className="cc-aoo-logo" alt="Mohawk Group Logo" src={logo} />
      {user && user.username ? (
        <>
          {/* <i className="fas fa-user-alt"></i> */}
          <i className="fsn sap-icon-person-placeholder"></i>
          &nbsp; {!shortenMsg ? "Signed in as " : ""}
          {user.username}
          {/* &nbsp;&nbsp;
          <a href="/logout">Sign out</a> */}
        </>
      ) : null}
      <span className="cc-aoo-back-to-link">
        <a href="https://my351458.crm.ondemand.com">Back to SAP</a>
        &nbsp;&nbsp;
        <a href="/acl_users/saml_login/initiate_logout?idp=proxyEntity">
          Logout
        </a>
      </span>
    </div>
  );
}

export default Header;
