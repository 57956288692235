import { useEffect, useState } from "react";

function useBreakpoint(breakpointQuery) {
  const [breakpointPassed, setBreakpointPassed] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(breakpointQuery);
    function updateBreakpointPassed() {
      setBreakpointPassed(mediaQuery.matches);
    }
    updateBreakpointPassed();
    mediaQuery.addListener(updateBreakpointPassed);
    return () => {
      mediaQuery.removeListener(updateBreakpointPassed);
    };
  }, [breakpointQuery]);

  return breakpointPassed;
}

export default useBreakpoint;
